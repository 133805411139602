import { useState, useEffect } from "react";
import About from "../components/About";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Management from "../components/Management";
import Memorijal from "../components/Memorijal";
import Trainers from "../components/Trainers";
import Why from "../components/Why";

const Naslovna = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  // This function will scroll the window to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
  };

  return (
    <>
      <div className="main">
        {showButton && (
          <button onClick={scrollToTop} className="toTopBtn">
            <i className="fas fa-arrow-up"></i>
          </button>
        )}
        <Header />
        <About />
        <Why />
        <Management />
        <Memorijal />
        <Trainers />
        <Footer />
      </div>
    </>
  );
};

export default Naslovna;
