import React from "react";
import why from "../assets/img/why.svg";
import wave2 from "../assets/img/wave2.svg";
const Why = () => {
  return (
    <>
      <section className="why" id="why">
        <div className="container">
          <h1 className="main-title">Zašto veslanje?</h1>
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="why-section">
                <h1 className="name">HVK Mornar</h1>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Consequatur ipsum delectus eligendi corrupti impedit at animi
                  dolores hic voluptas omnis aliquid eos iure veritatis quidem
                  fuga, perferendis maiores natus? Ipsam?
                </p>
                <div className="checkmark">
                  <p>Puno znoja ✅</p>
                  <p>Odlična ekipa ✅</p>
                  <p>Pametan pristup treningu ✅</p>
                  <p>Regate ✅</p>
                  <p>Izležavanje ❌</p>
                  <img style={{ width: "50%" }} src={wave2} alt="Wave" />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <img className="why-img" src={why} alt="Why rowing" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Why;
