import React, { useState } from "react";
import perica from "../assets/img/Perica.jpeg";
import { CSSTransition } from "react-transition-group";

const data = [
  {
    year: 2006,
    winner: "Niko Bujas",
    club: "Krka Šibenik",
  },
  {
    year: 2007,
    winner: "Bojan Žugić",
    club: "Mladost Zagreb",
  },
  {
    year: 2008,
    winner: "Regata nije održana",
    club: "",
  },
  {
    year: 2009,
    winner: "Leonard Šare",
    club: "Krka Šibenik",
  },
  {
    year: 2010,
    winner: "Stipe Bučić",
    club: "Gusar Split",
  },
  {
    year: 2011,
    winner: "Niko Bujas",
    club: "Krka Šibenik",
  },
  {
    year: 2012,
    winner: "Jure Šesnić",
    club: "Mornar Split",
  },
  {
    year: 2013,
    winner: "Alen Banovac",
    club: "Gusar Split",
  },
  {
    year: 2014,
    winner: "Vitomir Čavrlj",
    club: "Mornar Split",
  },
  {
    year: 2015,
    winner: "Vitomir Čavrlj",
    club: "Mornar Split",
  },
  {
    year: 2016,
    winner: "Vitomir Čavrlj",
    club: "Mornar Split",
  },
  {
    year: 2017,
    winner: "Leon Mikulić",
    club: "Jadran Zadar",
  },
  {
    year: 2018,
    winner: "Goran Mahmutović",
    club: "Mornar Split",
  },
  {
    year: 2019,
    winner: "Matej Metković",
    club: "Neptun Dubrovnik",
  },
];

const Memorijal = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <CSSTransition
        in={isOpen}
        timeout={300}
        classNames="modal-transition"
        unmountOnExit
      >
        <div className="modal">
          <div className="modal-body">
            <h3>Perica Vlašić (1932.-2004.)</h3>
            <p>Uspjesi</p>
            <ul>
              <li>sudionik na sedam europskih prvenstava</li>
              <li>sudionik OI u Melbourneu 1956.</li>
              <li>sudionik OI u Rimu 1960. godine</li>
              <li>
                osmerostruki uzastopni državni prvak u skifu (sedam puta sa
                suvozačem Nikolom Lučinom iz Splita)
              </li>
            </ul>
            <p>Priznanja</p>
            <ul>
              <li>
                1953.: najbolji športaš Hrvatske u izboru Sportskih novosti
              </li>
              <li>
                1995.: Državna nagrada za šport “Franjo Bučar” za 1995. godinu
              </li>
              <li>1995.: priznanje Općine Vela Luka za životno djelo</li>
            </ul>
            <table>
              <tr>
                <th>Godina</th>
                <th>Pobjednik</th>
                <th>Klub</th>
              </tr>
              {data.map((item) => (
                <tr>
                  <td>{item.year}</td>
                  <td>{item.winner}</td>
                  <td>{item.club}</td>
                </tr>
              ))}
            </table>
            <button
              onClick={toggleModal}
              style={{ marginTop: "20px" }}
              className="blueBtn"
            >
              Zatvori
            </button>
          </div>
        </div>
      </CSSTransition>
      <section className="memorijal" id="memorijal">
        <div className="container">
          <h1 className="main-title">Memorijal Perice Vlašić</h1>
          <div className="row align-items-center">
            <div className="col-md-6 pt-5">
              <img className="perica" src={perica} alt="Perica Vlašić" />
            </div>
            <div className="col-md-6 pt-5">
              <div className="perica-info">
                <h1>Perica Vlašić</h1>
                <p>
                  Perica Vlašić rodio se u Veloj Luci u siromašnoj ribarskoj
                  obitelji. Veslačku je karijeru započeo u novoosnovanom klubu u
                  rodnoj Veloj Luci VK Ošjak. Počeo je u četvercu. Sa 16 godina
                  nastupio je u četvercu na prvenstvu Hrvatske u Rijeci 1948.
                  godine, gdje su pobijedili.
                  <br />
                  <br />
                  Sljedeće godine njegov je četverac pobijedio u omladinskoj
                  konkurenciji na prvenstvu Hrvatske u Šibeniku. 1950. godine
                  njegov četverac na prvenstvu Hrvatske u Puli pobijedio je u
                  konkurenciji juniora. Iste je godine prvi put okušao se u
                  skifu.1951. se opet natjecao na prvenstvu Hrvatske u Zadru.
                  <br />
                  <br />
                  Njegov je četverac opet pobijedio, ovog puta u seniorskoj
                  konkurenciji. Poslije se okrenuo natjecanju u skifu. Nakon
                  odsluženja vojnog roka u Puli, legendarni Luka Marasović odveo
                  ga je u Split. Ondje se pridružio VK Mornaru s kojim se je već
                  natjecao na prvenstvu Hrvatske u Splitu 1953. godine.
                  Pobijedio je i postao prvak Hrvatske i neslužbeni seniorski
                  prvak Jugoslavije.
                  <br />
                  <br />
                  Od iste godine veslao je za državnu reprezentaciju. za
                  seniore. Iste je godine postao državni prvak na prvenstvu u
                  Šibeniku u disciplini skif i double-skull. Uskoro je veslao na
                  europskim prvenstvima, gdje je osvojio zlatna odličja.
                </p>
                <button onClick={toggleModal} className="blueBtn">
                  Vidi rezultate
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Memorijal;
