import React from "react";
import logo from "../assets/img/logo.png";
const Management = () => {
  return (
    <>
      <section className="management" id="upravljanje">
        <div className="container">
          <h1 className="main-title">Upravljanje</h1>
          <div className="row pt-5">
            <div className="col-md-4 pt-5">
              <div className="management-container">
                <h3>Predsjednik</h3>
                <li>Marko Pešić</li>
                <h3>Podpredsjednik</h3>
                <li>Almir Mahmutović</li>
                <h3>Tajnik</h3>
                <li>Marko Pešić</li>
              </div>
            </div>
            <div className="col-md-4 pt-5">
              <div className="management-container">
                <h3>Članovi</h3>
                <li>Almir Mahmutović</li>
                <li>Almir Mahmutović</li>
                <li>Almir Mahmutović</li>
                <li>Almir Mahmutović</li>
              </div>
            </div>
            <div className="col-md-4 pt-5">
              <div className="management-container">
                <img src={logo} alt="" />
              </div>
            </div>
          </div>

          <button className="blueBtn">Statut</button>
        </div>
      </section>
    </>
  );
};

export default Management;
