import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import about1 from "../assets/img/about1.jpg";
import ar from "../assets/img/ar.svg";
import al from "../assets/img/al.svg";
const data = [
  {
    id: 1,
    title: "About",
    image: about1,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, nunc ut aliquam aliquam, nunc nisl aliquet nisl, eget aliquam nisl nisl sit amet nisl. Sed euismod, nunc ut aliquam aliquam, nunc nisl aliquet nisl, eget aliquam nisl nisl sit amet nisl.",
  },
  {
    id: 2,
    title: "About",
    image: about1,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, nunc ut aliquam aliquam, nunc nisl aliquet nisl, eget aliquam nisl nisl sit amet nisl. Sed euismod, nunc ut aliquam aliquam, nunc nisl aliquet nisl, eget aliquam nisl nisl sit amet nisl.",
  },
];

const About = () => {
  const ref = useRef(null);

  const handleNextSlide = () => {
    ref.current.slickNext();
  };

  const handlePrevSlide = () => {
    ref.current.slickPrev();
  };

  const settings = {
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
  };

  return (
    <>
      <div className="about container" id="about">
        <h1 className="main-title">O nama</h1>
        <Slider ref={ref} settings={settings}>
          {data.map((item) => (
            <div key={item.id}>
              <div className="row align-items-center">
                <div className="col-md-6">
                  <img src={item.image} className="about-image" alt="About" />
                </div>
                <div className="col-md-6">
                  <div className="about-content">
                    <h2>{item.title}</h2>
                    <p>{item.description}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        <div className="arrows">
          <img src={al} alt="arrow" onClick={handlePrevSlide} />
          <img src={ar} alt="arrow" onClick={handleNextSlide} />
        </div>
      </div>
    </>
  );
};

export default About;
