import "./scss/Main.scss";
import Naslovna from "./pages/Naslovna";
import { Route, Routes, useLocation } from "react-router-dom";
function App() {
  const location = useLocation();
  return (
    <>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Naslovna />} />
      </Routes>
    </>
  );
}

export default App;
