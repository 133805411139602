import React from "react";
import crnjo from "../assets/img/crnjo.svg";
const Trainers = () => {
  return (
    <>
      <section className="trainers" id="treneri">
        <div className="container">
          <h1 className="main-title">Treneri</h1>
          <div className="row">
            <div className="col-md-4 text-center">
              <div className="trainer">
                <img src={crnjo} alt="Crnjo" />
                <h3>Luka Vrgoč</h3>
                <button className="blueBtn">Kondicija</button>
                <div>
                  <a href="telto:+3859993323">+3859993323</a>
                </div>
              </div>
            </div>
            <div className="col-md-4 text-center">
              <div className="trainer">
                <img src={crnjo} alt="Crnjo" />
                <h3>Denis Smajilović</h3>
                <button className="blueBtn">Snaga</button>
                <div>
                  <a href="telto:+3859993323">+3859993323</a>
                </div>
              </div>
            </div>
            <div className="col-md-4 text-center">
              <div className="trainer">
                <img src={crnjo} alt="Crnjo" />
                <h3>Ivan Fistonić</h3>
                <button className="blueBtn">Tehnika</button>
                <div>
                  <a href="telto:+3859993323">+3859993323</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Trainers;
