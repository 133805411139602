import { useState } from "react";
import logo from "../assets/img/logo.png";
import wave from "../assets/img/wave.svg";
import smallWave from "../assets/img/small-wave.svg";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toogleMenu = () => {
    setIsOpen(!isOpen);
  };

  // If i click on link, the menu should be closed
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header>
      <img className="wave" src={wave} alt="" />
      <nav className="container d-flex align-items-center justify-content-between">
        <div className="logo d-flex align-items-center">
          <img src={logo} alt="Logo" className="logo-img" />
          <h1>HVK Mornar Split</h1>
        </div>
        <ul className="links d-flex align-items-center">
          <li>
            <a href="/">Naslovna</a>
          </li>
          <li>
            <a href="#about">O nama</a>
          </li>
          <li>
            <a href="#why">Zašto veslanje</a>
          </li>
          <li>
            <a href="#treneri">Treneri</a>
          </li>
          <li>
            <a href="#upravljanje">Upravljanje</a>
          </li>
          <li>
            <a href="#memorijal">Memorijal</a>
          </li>
          <li>
            <a href="#kontakt">Kontakt</a>
          </li>
        </ul>
        <div className="overlay" style={{ width: isOpen ? "100%" : "0%" }}>
          <ul>
            <li>
              <a href="/">Naslovna</a>
            </li>
            <li>
              <a onClick={toogleMenu} href="#about">
                O nama
              </a>
            </li>
            <li>
              <a onClick={handleClick} href="#why">
                Zašto veslanje
              </a>
            </li>
            <li>
              <a onClick={handleClick} href="#treneri">
                Treneri
              </a>
            </li>
            <li>
              <a onClick={handleClick} href="#upravljanje">
                Upravljanje
              </a>
            </li>
            <li>
              <a onClick={handleClick} href="#memorijal">
                Memorijal
              </a>
            </li>
            <li>
              <a onClick={handleClick} className="contact" href="">
                Kontakt
              </a>
            </li>
          </ul>
        </div>
        <div className="hamburger" onClick={toogleMenu}>
          <svg
            className={
              isOpen ? "active ham hamRotate ham8" : "ham hamRotate ham8"
            }
            viewBox="0 0 100 100"
            width="80"
          >
            <path
              className="line top"
              d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"
            />
            <path className="line middle" d="m 30,50 h 40" />
            <path
              className="line bottom"
              d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"
            />
          </svg>
        </div>
      </nav>
      <div className="header--content container">
        <h1>HVK Mornar</h1>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maiores, aut
          hic repellat vitae assumenda id laboriosam quam aspernatur debitis in,
          architecto repudiandae dolorum eveniet unde consequuntur atque! Harum,
          enim corporis?
        </p>
        <button className="blueBtn">Pregledaj</button>
      </div>
      <img src={smallWave} alt="" className="smallWave" />
    </header>
  );
};

export default Header;
