import React from "react";
import logo from "../assets/img/logo.png";
const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="footer__wrapper">
          <div className="footer_wrap">
            <div className="footer-logo">
              <img style={{ width: "100px" }} src={logo} alt="" />
              <h3>HVK MORNAR</h3>
            </div>
            <div className="footer-links">
              <ul>
                <li>
                  <a href="/">Naslovna</a>
                </li>
                <li>
                  <a href="/about">O nama</a>
                </li>
                <li>
                  <a href="/quality-management">Treneri</a>
                </li>
                <li>
                  <a href="/contact">Upravljanje</a>
                </li>
                <li>
                  <a href="/contact">Memorijal</a>
                </li>
                <li>
                  <a href="/contact">Kontakt</a>
                </li>
              </ul>
            </div>
            <div className="info">
              <a href="" className="location">
                Location
              </a>
              <a href="" className="telephone">
                Email
              </a>
            </div>
          </div>
          <div className="copyright__wrapper">
            <p className="copyright">© 2021 HVK Mornar All rights reserved.</p>
            <p>
              HandCrafted by{" "}
              <a
                className="vik"
                href="https://www.instagram.com/viktorbilokapic/"
              >
                VB
              </a>
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
